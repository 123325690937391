var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.$route.name,"items":_vm.items}}),_c('b-card',[(!_vm.isLoading)?_c('b-card-body',[_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.setDataClothDetail),function(cloth,indexCloth){return _c('div',{key:indexCloth,staticClass:"col-sm-4 col-md-3 col-lg-2 col-custom my-2 d-flex"},[_c('div',{staticClass:"card card-custom ratio ratio-1x1 animate-card text-white w-100",on:{"click":function($event){return _vm.addFormDataSize(cloth.id)}}},[_c('img',{staticClass:"card-img h-100",staticStyle:{"object-fit":"cover"},attrs:{"src":cloth.cloth_colors[0].image !== null
                    ? ((_vm.apiUrl.baseUrl) + "/storage/" + (cloth.cloth_colors[0].image))
                    : _vm.imgDummy,"alt":"..."}}),_c('div',{staticClass:"card-img-overlay",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"bottom-left fw-bold card-title-custome"},[_c('p',{staticClass:"text-dark"},[_vm._v(" "+_vm._s(cloth.cloth_category ? cloth.cloth_category.name : "-")+" ")])])])])])}),0),_c('div',{staticClass:"mt-3"},[(_vm.filteredDataFinal.length > 0)?[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered"},[_c('thead',{staticClass:"head-custom fw-bold"},[_c('tr',{staticClass:"text-center font-size-15"},[_c('td',{attrs:{"rowspan":"2"}}),_c('td',{attrs:{"rowspan":"2"}},[_vm._v("Produk")]),_c('td',{attrs:{"rowspan":"2"}},[_vm._v("Warna")]),_c('td',{attrs:{"rowspan":"2"}},[_vm._v("Ukuran")]),_c('td',{attrs:{"colspan":"3"}},[_vm._v("Min. Stok")])])]),_c('tbody',{staticClass:"text-center align-middle"},[_vm._l((_vm.filteredDataFinal),function(item){return [_vm._l((item.cloth_colors),function(color){return (_vm.totalCurrentStockIndex(color.id) > 0)?[_c('tr',{staticClass:"text-center align-middle"},[_c('td',{attrs:{"rowspan":_vm.totalRowspan(color.id)}},[_c('img',{staticClass:"avatar-md img-responsive shadow border rounded",staticStyle:{"object-fit":"cover"},attrs:{"src":color.image !== null
                                ? ((_vm.apiUrl.baseUrl) + "storage/" + (color.image))
                                : _vm.imgDummy,"alt":"Image"}})]),_c('td',{staticClass:"fw-bold",attrs:{"rowspan":_vm.totalRowspan(color.id)}},[_vm._v(" "+_vm._s(item.cloth_category ? item.cloth_category.name : "-")+" ")]),_c('td',{staticClass:"fw-bold",attrs:{"rowspan":_vm.totalRowspan(color.id)}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center gap-2"},[_c('span',{staticClass:"indicator-color-two border border-1 border-secondary",style:({
                                background: ("#" + (color.color.code_hexa)),
                              })})])]),(
                            color.cloth_sizes.some(
                              function (size) { return parseInt(size.current_stock) > 0; }
                            )
                          )?[_c('td',[_vm._v(" "+_vm._s(color.cloth_sizes[ _vm.indexWithStockGreaterThanZeroForColor( color.id ) ].size.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(color.cloth_sizes[ _vm.indexWithStockGreaterThanZeroForColor( color.id ) ].current_stock)+" ")])]:_vm._e()],2),_vm._l((color.cloth_sizes),function(size,indexSize){return (
                          parseInt(size.current_stock) > 0 &&
                            indexSize >
                              _vm.indexWithStockGreaterThanZeroForColor(color.id)
                        )?_c('tr',{key:size.id},[_c('td',[_vm._v(_vm._s(size.size.name))]),_c('td',[_vm._v(_vm._s(size.current_stock))])]):_vm._e()})]:_vm._e()})]})],2)])])]:_vm._e(),_c('div',{staticClass:"mt-4"},[_c('b-button',{staticClass:"btn-block fw-bold",attrs:{"variant":"success","disabled":_vm.total_current_stock > 0 ? false : true},on:{"click":_vm.onSubmit}},[_vm._v("Simpan")])],1)],2)])]):_c('b-card-body',[_c('div',{staticClass:"row"},[_vm._l((_vm.rowCount),function(row){return _c('div',{key:row,staticClass:"col-sm-4 col-md-3 col-lg-2 mt-3"},[_c('skeleton',{attrs:{"width":"100%","height":"12rem","borderRadius":"7px"}})],1)}),_c('div',{staticClass:"mt-4"},[_c('skeleton',{attrs:{"width":"100%","height":"7rem","borderRadius":"7px"}})],1)],2)])],1),_c('b-modal',{ref:"tambah-item-modal-detail",staticClass:"pl-0",attrs:{"size":"lg","hide-footer":"","centered":"","title":_vm.filteredData.length > 0
        ? _vm.filteredData[0].cloth_category
          ? _vm.filteredData[0].cloth_category.name
          : '-'
        : '-'}},[(!_vm.isLoadingModalDetail)?_c('div',{staticClass:"container-fluid"},[(_vm.filteredData.length > 0)?[_c('multiselect',{attrs:{"options":_vm.setDataClothColor.cloth_colors,"searchable":true,"track-by":"id","placeholder":"Select a color"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
        var option = ref.option;
return [_vm._v(" "+_vm._s(option.color !== null ? option.color.name : "-")+" ")]}},{key:"singleLabel",fn:function(ref){
        var option = ref.option;
return [_vm._v(" "+_vm._s(option.color !== null ? option.color.name : "-")+" ")]}}],null,false,1258727926),model:{value:(_vm.selectedColor),callback:function ($$v) {_vm.selectedColor=$$v},expression:"selectedColor"}}),_c('div',{staticClass:"table-responsive mt-4"},[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',[_c('tr',{staticClass:"text-center vertical-align-middle"},[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Ukuran")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Min. Stok")])])]),_c('tbody',_vm._l((_vm.selectedColor.cloth_sizes),function(select){return _c('tr',{key:select.id,staticClass:"text-center"},[_c('td',[_vm._v(_vm._s(select.size.name))]),_c('td',[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-button',{staticClass:"rounded-start rounded-0",attrs:{"variant":"primary"},on:{"click":function($event){select.current_stock--}}},[_vm._v("-")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(select.current_stock),expression:"select.current_stock"}],staticClass:"form-control min-stock rounded-0 text-end",attrs:{"type":"number","id":select.id + 'form-current-stock',"placeholder":"Masukkan Min. Stok"},domProps:{"value":(select.current_stock)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(select, "current_stock", $event.target.value)}}}),_c('b-button',{staticClass:"rounded-end rounded-0",attrs:{"variant":"primary"},on:{"click":function($event){select.current_stock++}}},[_vm._v("+")])],1)])])}),0)])])]:_vm._e()],2):_c('div',[_vm._v("loading...")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }